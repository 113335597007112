

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Srilanka from "layouts/applications/srilanka";
import ViewPage from "layouts/applications/srilanka/ViewPage"
import SignInBasic from "layouts/authentication/sign-in/basic";
// import SignInCover from "layouts/authentication/sign-in/cover";
// import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";

import AuthWrapper from "AuthWrapper/AuthWrapper";

const routes = [
  {
    type: "collapse",
    name: "Brooklyn Alice",
    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in/basic",
        // component: <SignInBasic />,
        component: <AuthWrapper component={SignInBasic} />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        // component: <Analytics />,
        component: <AuthWrapper component={Analytics} />,
      },
    ],
  },
  { type: "title", title: "Pages", key: "title-pages" },

  {
    type: "collapse",
    name: "Applications",
    key: "applications",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Srilanka",
        key: "data-tables",
        route: "/applications/srilanka",
        // component: <DataTables />,
        component: <AuthWrapper component={Srilanka} />,
      },
      {
        name: "Srilanka",
        key: "data-table",
        route: "/applications/srilanka/:id",
        // component: <DataTables />,
        component: <AuthWrapper component={ViewPage} />,
      },
    ],
  },


  {
    type: "collapse",
    name: "Authentication",
    key: "authentication",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic />,
        // collapse: [
        //   {
        //     name: "Basic",
        //     key: "basic",
        //     route: "/authentication/sign-in/basic",
        //     component: <SignInBasic />,
        //   },
        //   // {
        //   //   name: "Cover",
        //   //   key: "cover",
        //   //   route: "/authentication/sign-in/cover",
        //   //   component: <SignInCover />,
        //   // },
        //   // {
        //   //   name: "Illustration",
        //   //   key: "illustration",
        //   //   route: "/authentication/sign-in/illustration",
        //   //   component: <SignInIllustration />,
        //   // },
        // ],
      },
      {
        name: "Sign Up",
        key: "sign-up",
        route: "/authentication/sign-up/cover",
        component: <SignUpCover />,
        // collapse: [
        //   {
        //     name: "Sing Up",
        //     key: "cover",
        //     route: "/authentication/sign-up/cover",
        //     component: <SignUpCover />,
        //   },
        // ],
      },
      {
        name: "Reset Password",
        key: "reset-password",
        route: "/authentication/reset-password/cover",
        component: <ResetCover />,
        // collapse: [
        //   {
        //     name: "Cover",
        //     key: "cover",
        //     route: "/authentication/reset-password/cover",
        //     component: <ResetCover />,
        //   },
        // ],
      },
    ],
  },
  { type: "divider", key: "divider-1" },
  { type: "title", title: "Docs", key: "title-docs" },

];

export default routes;
