import { Box, Typography, Link } from '@mui/material';
import "./footer.css";
function Footer() {
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        padding: '1rem', 
        margin:"5px",
        backgroundColor: '#f5f5f5', 
        position: 'relative', 
        bottom: 0, 
        width: 'auto', 
        border:"1px solid #6e6969",
        borderRadius:"7px",
      }}
    >
      <Typography variant="body2" color="textSecondary">
        © {new Date().getFullYear()} Benzene Nexus
      </Typography>
      <Box>
        <Link href="/privacy-policy" color="inherit" underline="none" sx={{ marginRight: '1rem' }}>
          Privacy Policy
        </Link>
        <Link href="/terms-of-service" color="inherit" underline="none">
          Terms of Service
        </Link>
      </Box>
    </Box>
  );
}
export default Footer;
