import "./viewPage.css";
import { useEffect, useState, useCallback } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNavigate, useParams } from "react-router-dom";
import "./kms.css";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { api } from "utils/api";
import { Button, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const baseURL = "https://www.allexamtest.com";

function ViewPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formdata, setformdata] = useState({});
  const [copiedField, setCopiedField] = useState(null); // Track which field was copied

  const fetchApplicationDetail = useCallback(async () => {
    if (!id) return;
    try {
      const response = await api.get(`/api/srilanka/View-application/${id}`);
      if (response.data && response.data.status) {
        setformdata(response.data.data);
      }
    } catch (error) {
      alert(error.response.data.message || "something went wrong");
    }
  }, [id]);

  useEffect(() => {
    fetchApplicationDetail();
  }, [fetchApplicationDetail]);

  const back = () => {
    navigate(`/applications/srilanka`);
  };

  let timer = 3000
  const handleCopy = (field) => {
    setCopiedField(field);
    timer = 3000
    setTimeout(() => setCopiedField(null), timer); // Clear the copied field after 3 seconds
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Sri-lanka Visa Applications
            </MDTypography>
            <Button onClick={back} id="backbtn"> Back</Button>
            <MDTypography variant="button" color="text">
              PAID || UNPAID APPLICATION
            </MDTypography>
          </MDBox>
          <>
            {formdata && (
              <div className='srilanka'>
                <div className="centerCol">
                  <div className="welcomeText">
                    <h3> Preview Your Application </h3>
                    <hr style={{ width: '290px', borderWidth: '1px', borderStyle: 'solid', borderColor: 'black' }} />
                    <Container className='pagesa4'>
                      <PreviewField
                        label="Application Number :-"
                        value={formdata.sku_id}
                        copied={copiedField === formdata.sku_id}
                        onCopy={() => handleCopy(formdata.sku_id)}
                      />
                      <PreviewField
                        label="Your Nationality :-"
                        value={formdata.nationality}
                        copied={copiedField === formdata.nationality}
                        onCopy={() => handleCopy(formdata.nationality)}
                      />
                      <PreviewField
                        label="Country Of Residence :-"
                        value={formdata.country_of_residence}
                        copied={copiedField === "country_of_residence"}
                        onCopy={() => handleCopy("country_of_residence")}
                      />
                      <PreviewField
                        label="Visa Type :-"
                        value={formdata.visa_type}
                        copied={copiedField === formdata.visa_type}
                        onCopy={() => handleCopy(formdata.visa_type)}
                      />
                      <PreviewField
                        label="Visa Category :-"
                        value={formdata.visa_categroy}
                        copied={copiedField === formdata.visa_categroy}
                        onCopy={() => handleCopy(formdata.visa_categroy)}
                      />
                      <PreviewField
                        label="Purpose of Visit :-"
                        value={formdata.purpose_of_visit}
                        copied={copiedField === formdata.purpose_of_visit}
                        onCopy={() => handleCopy(formdata.purpose_of_visit)}
                      />
                      <PreviewField
                        label="Sub Visa Type :-"
                        value={formdata.sub_visa_type}
                        copied={copiedField === formdata.sub_visa_type}
                        onCopy={() => handleCopy(formdata.sub_visa_type)}
                      />
                      <PreviewField
                        label="Type of Travel Document :-"
                        value={formdata.type_of_travel_doc}
                        copied={copiedField === formdata.type_of_travel_doc}
                        onCopy={() => handleCopy(formdata.type_of_travel_doc)}
                      />
                      <PreviewField
                        label="Email :-"
                        value={formdata.email}
                        copied={copiedField === formdata.email}
                        onCopy={() => handleCopy(formdata.email)}
                      />
                      <PreviewField
                        label="Country Code :-"
                        value={formdata.country_code}
                        copied={copiedField === formdata.country_code}
                        onCopy={() => handleCopy(formdata.country_code)}
                      />
                      <PreviewField
                        label="Mobile/Cell Phone Number :-"
                        value={formdata.mobile_number}
                        copied={copiedField === formdata.mobile_number}
                        onCopy={() => handleCopy(formdata.mobile_number)}
                      />

                      <div className="formvisa_apply">Passport Details</div>
                      <hr style={{ width: '200px', borderWidth: '1px', borderStyle: 'solid', borderColor: 'black' }} />
                      <PreviewField
                        label="Passport Number :-"
                        value={formdata.passport_number}
                        copied={copiedField === formdata.passport_number}
                        onCopy={() => handleCopy(formdata.passport_number)}
                      />
                      <PreviewField
                        label="Passport Issued Date :-"
                        value={formdata.passport_issued_date}
                        copied={copiedField === formdata.passport_issued_date}
                        onCopy={() => handleCopy(formdata.passport_issued_date)}
                      />
                      <PreviewField
                        label="Passport Expiry Date :-"
                        value={formdata.passport_expiry_date}
                        copied={copiedField === formdata.passport_expiry_date}
                        onCopy={() => handleCopy(formdata.passport_expiry_date)}
                      />
                      <PreviewField
                        label="Date of Birth :-"
                        value={formdata.date_of_birth}
                        copied={copiedField === formdata.date_of_birth}
                        onCopy={() => handleCopy(formdata.date_of_birth)}
                      />
                      <PreviewField
                        label="Title :-"
                        value={formdata.title}
                        copied={copiedField === formdata.title}
                        onCopy={() => handleCopy(formdata.title)}
                      />
                      <PreviewField
                        label="Surname/Family Name :-"
                        value={formdata.surname}
                        copied={copiedField === formdata.surname}
                        onCopy={() => handleCopy(formdata.surname)}
                      />
                      <PreviewField
                        label="Other/Given Names :-"
                        value={formdata.other_name}
                        copied={copiedField === formdata.other_name}
                        onCopy={() => handleCopy(formdata.other_name)}
                      />
                      <PreviewField
                        label="Passport Place of Issue :-"
                        value={formdata.passport_place_of_issue}
                        copied={copiedField === formdata.passport_place_of_issue}
                        onCopy={() => handleCopy(formdata.passport_place_of_issue)}
                      />
                      <PreviewField
                        label="Place of Birth :-"
                        value={formdata.place_of_birth}
                        copied={copiedField === formdata.place_of_birth}
                        onCopy={() => handleCopy(formdata.place_of_birth)}
                      />
                      <PreviewField
                        label="Marital Status :-"
                        value={formdata.marital_status}
                        copied={copiedField === formdata.marital_status}
                        onCopy={() => handleCopy(formdata.marital_status)}
                      />
                      <PreviewField
                        label="Gender :-"
                        value={formdata.gender}
                        copied={copiedField === formdata.gender}
                        onCopy={() => handleCopy(formdata.gender)}
                      />

                      <div className="formvisa_apply">Travel Information</div>
                      <hr style={{ width: '220px', borderWidth: '1px', borderStyle: 'solid', borderColor: 'black' }} />
                      <PreviewField
                        label="Date of Arrival in Sri Lanka :-"
                        value={formdata.date_of_arrivalIn_srilanka}
                        copied={copiedField === formdata.date_of_arrivalIn_srilanka}
                        onCopy={() => handleCopy(formdata.date_of_arrivalIn_srilanka)}
                      />
                      <PreviewField
                        label="Port of Entry in Sri Lanka :-"
                        value={formdata.port_of_entry_in_srilanka}
                        copied={copiedField === formdata.port_of_entry_in_srilanka}
                        onCopy={() => handleCopy(formdata.port_of_entry_in_srilanka)}
                      />
                      <PreviewField
                        label="Place of Stay in Sri Lanka :-"
                        value={formdata.stay_in_srilanka}
                        copied={copiedField === formdata.stay_in_srilanka}
                        onCopy={() => handleCopy(formdata.stay_in_srilanka)}
                      />
                      <PreviewField
                        label="Date of Proposed Departure from Sri Lanka :-"
                        value={formdata.date_of_propos_dept_srilanka}
                        copied={copiedField === formdata.date_of_propos_dept_srilanka}
                        onCopy={() => handleCopy(formdata.date_of_propos_dept_srilanka)}
                      />

                      {formdata.visa_categroy === "Business" && (
                        <>
                          <PreviewField
                            label="Company Details in Sri Lanka :-"
                            value={formdata.srilanka_comp_details}
                            copied={copiedField === formdata.srilanka_comp_details}
                            onCopy={() => handleCopy(formdata.srilanka_comp_details)}
                          />
                          <PreviewField
                            label="Company Zip in Sri Lanka :-"
                            value={formdata.company_zip_details}
                            copied={copiedField === formdata.company_zip_details}
                            onCopy={() => handleCopy(formdata.company_zip_details)}
                          />
                          <PreviewField
                            label="Company City in Sri Lanka :-"
                            value={formdata.company_city}
                            copied={copiedField === formdata.company_city}
                            onCopy={() => handleCopy(formdata.company_city)}
                          />
                        </>
                      )}

                      <div className="formvisa_apply">Contact Details</div>
                      <hr style={{ width: '190px', borderWidth: '1px', borderStyle: 'solid', borderColor: 'black' }} />
                      <PreviewField
                        label="Address Line 1 in Sri Lanka :-"
                        value={formdata.address_line1}
                        copied={copiedField === formdata.address_line1}
                        onCopy={() => handleCopy(formdata.address_line1)}
                      />
                      <PreviewField
                        label="Address Line 2 in Sri Lanka :-"
                        value={formdata.address_line2}
                        copied={copiedField === formdata.address_line2}
                        onCopy={() => handleCopy(formdata.address_line2)}
                      />
                      <PreviewField
                        label="City :-"
                        value={formdata.city}
                        copied={copiedField === formdata.city}
                        onCopy={() => handleCopy(formdata.city)}
                      />
                      <PreviewField
                        label="State :-"
                        value={formdata.state}
                        copied={copiedField === formdata.state}
                        onCopy={() => handleCopy(formdata.state)}
                      />
                      <PreviewField
                        label="Zip/Postal Code :-"
                        value={formdata.postal_code}
                        copied={copiedField === formdata.postal_code}
                        onCopy={() => handleCopy(formdata.postal_code)}
                      />

                      <div className="formvisa_apply">Required Document</div>
                      <hr style={{ width: '240px', borderWidth: '1px', borderStyle: 'solid', borderColor: 'black' }} />
                      <PreviewField
                        label="Passport Size Colored Photo :-"
                        fileUrl={formdata.passport_size_photo ? (`${baseURL}/uploads/${formdata.passport_size_photo}`) : ""}
                        value='Passport Size'
                        copied={copiedField === "passport_size_photo"}
                        onCopy={() => handleCopy("passport_size_photo")}
                      />
                      <PreviewField
                        label="Passport Bio Page :-"
                        fileUrl={formdata.passport_bio_page ? (`${baseURL}/uploads/${formdata.passport_bio_page}`) : ""}
                        value='Passport Bio Page'
                        copied={copiedField === "passport_bio_page"}
                        onCopy={() => handleCopy("passport_bio_page")}
                      />

                      {formdata.visa_categroy === "Business" && (
                        <>
                          <PreviewField
                            label="Invitation Letter from Host Company in Sri Lanka :-"
                            fileUrl={formdata.invitation_latter ? (`${baseURL}/uploads/${formdata.invitation_latter}`) : ""}
                            value='Invitation Letter'
                            copied={copiedField === "invitation_latter"}
                            onCopy={() => handleCopy("invitation_latter")}
                          />
                          {/* <PreviewField
                            label="Accommodation Proof :-"
                            fileUrl={formdata.accommodation_proof ? (`${baseURL}/uploads/${formdata.accommodation_proof}`) : ""}
                            value='Accommodation Proof'
                            copied={copiedField === "accommodation_proof"}
                            onCopy={() => handleCopy("accommodation_proof")}
                          />
                          <PreviewField
                            label="Travel Plan/Itinerary :-"
                            fileUrl={formdata.travel_plan ? (`${baseURL}/uploads/${formdata.travel_plan}`) : ""}
                            value='Travel Plan'
                            copied={copiedField === "travel_plan"}
                            onCopy={() => handleCopy("travel_plan")}
                          /> */}
                        </>
                      )}
                    </Container>
                  </div>
                </div>
              </div>
            )}
          </>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ViewPage;


const PreviewField = ({ label, value, fileUrl, copied, onCopy }) => {
  const handleDoubleClick = () => {
    navigator.clipboard.writeText(fileUrl || value).then(() => {
      onCopy(); // Notify parent component
    });
  };

  // const handleDownload = () => {
  //   if (fileUrl) {
  //     // Create a link element to trigger download
  //     const link = document.createElement('a');
  //     link.href = fileUrl;
  //     link.download = fileUrl.split('/').pop(); // Extract filename from URL
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  // };
  // const fileUrl = 'https://www.allexamtest.com/uploads/image-1723575139878-19433913.jpeg'; // Replace with your file URL

  const handleDownload = async () => {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) throw new Error('Network response was not ok');

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileUrl.split('/').pop(); // Extract filename from URL
      document.body.appendChild(link); // Append link to body
      link.click(); // Trigger download
      document.body.removeChild(link); // Remove link from body
      window.URL.revokeObjectURL(url); // Clean up
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  }
  return (
    <Row className="justify-content-md-center m-2">
      <Col sm={5} style={{ display: 'flex', alignItems: 'center' }}>
        <Form.Label
          htmlFor="basic-url"
          style={{ width: '300px', display: 'inline-block', marginRight: '10px' }}
        >
          <b>{label}</b>
        </Form.Label>
        {fileUrl ? (
          <div style={{ border: "1px solid", padding: "5px", display: "flex", gap: "30px", justifyContent: "center", alignItems: "center" }}>
            <img
              src={(fileUrl)}
              alt={value}
              style={{ maxWidth: 'auto', height: 'auto', }}
            />
            <Button
              variant="primary"
              onClick={handleDownload}
              style={{ margin: '10px', height: "30px", padding: "10px", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold" }}
            >
              Download
            </Button>
          </div>
        ) : <div
          onDoubleClick={handleDoubleClick}
          style={{
            display: 'flex',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '600px',
            whiteSpace: 'nowrap',
            height: '30px',
            border: '1px solid',
            borderRadius: '5px',
            padding: '0 6px',
            justifyContent: 'start',
            alignItems: 'center',
            fontSize: '20px',
            position: 'relative',
          }}
        >
          {value}
          {copied && (
            <span
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                backgroundColor: '#e0e0e0',
                padding: '2px 6px',
                borderRadius: '3px',
                fontSize: '12px',
                color: '#333',
              }}
            >
              Copied
            </span>
          )}
        </div>}
      </Col>
    </Row>
  );
};
