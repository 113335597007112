import React from "react";
import { Navigate } from "react-router-dom";
const AuthWrapper = ({ component: Component, ...rest }) => {
  // const token = localStorage.getItem("token");
  const token = true;
  return (
    <>
      {token ? <Component {...rest} /> : <Navigate to="/authentication/sign-in/cover" />}
    </>
  );
};

export default AuthWrapper;
