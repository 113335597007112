import React, { useState, useCallback, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import "./kms.css";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { api } from "utils/api";
import DataTable from "examples/Tables/DataTable";
import { Button } from "@mui/material";
import moment from 'moment-timezone';

const RemarkInput = ({ applicationId, initialRemark, onSave }) => {
  const [value, setValue] = useState(initialRemark || "");

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSave = () => {
    onSave(applicationId, value);
  };

  return (
    <div style={{ display: "flex", gap: "10px", justifyContent: "center", alignItems: "center" }}>
      <input
        id={applicationId}
        style={{ height: "40px", width: "300px", padding: "0 6px" }}
        value={value}
        placeholder={value ? "" : "TBD"}
        onChange={handleChange}
      />
      <Button onClick={handleSave} id="save_button">
        Save
      </Button>
    </div>
  );
};

function Srilanka() {
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  const viewDetails = (id) => {
    navigate(`/applications/srilanka/${id}`);
  };

  const fetchData = useCallback(async () => {
    try {
      const res = await api.get("/api/srilanka/get-application");
      const data = res.data.data;
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      const apiData = await fetchData();
      const transformedData = apiData.map((item) => ({
        sku_id: item.sku_id,
        email: item.email,
        nationality: item.nationality,
        gender: item.gender,
        date_of_birth: item.date_of_birth,
        surname: item.surname,
        visa_categroy: item.visa_categroy,
        action: item.sku_id,
        remarks: item.remarks || "",
        apply_date: moment(item.createdAt).tz('Asia/Kolkata').format('DD-MM-YYYY'),
        payment_status: item.payment_status ? item.payment_status === "pending" ? "PENDING" : item.payment_status === "unpaid" ? "UNPAID" : "PAID" : "TBC",
        apply_time: moment(item.createdAt).tz('Asia/Kolkata').format('hh:mm A'),
      }));
      setRows(transformedData);
    };

    getData();
  }, [fetchData]);

  const saveRemark = async (applicationId, value) => {
    try {
      await api.put("/api/srilanka/update-remarks", {
        applicationId,
        remark: value,
      });
      console.log(`Remarks updated for Application ID: ${applicationId}`);
    } catch (error) {
      console.error("Error updating remarks:", error);
    }
  };

  const dataTableData = {
    columns: [
      { Header: "Payment Status", accessor: "payment_status" },
      { Header: "Application Id", accessor: "sku_id", width: "20%" },
      { Header: "Apply Date", accessor: "apply_date", width: "15%" },
      { Header: "Apply Time", accessor: "apply_time", width: "15%" },
      { Header: "Email", accessor: "email", width: "25%" },
      { Header: "Nationality", accessor: "nationality" },
      { Header: "Gender", accessor: "gender" },
      { Header: "DOB", accessor: "date_of_birth" },
      { Header: "Sur Name", accessor: "surname" },
      { Header: "Visa Category", accessor: "visa_categroy" },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value }) => (
          <Button onClick={() => viewDetails(value)} id="view_button">
            View
          </Button>
        ),
      },
      {
        Header: "Remarks",
        accessor: "remarks",
        width: "40%",
        Cell: ({ row }) => (
          <RemarkInput
            applicationId={row.original.sku_id}
            initialRemark={row.original.remarks}
            onSave={saveRemark}
          />
        ),
      },
    ],
    rows: rows,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Sri-lanka Visa Application
            </MDTypography>
            <MDTypography variant="button" color="text">
              PAID || UNPAID APPLICATION
            </MDTypography>
          </MDBox>
          <DataTable table={dataTableData} canSearch />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Srilanka;
