
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function Analytics() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container>
        <h1> Welcome Sri-Lanka Visa </h1>
        </Grid>
       
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
